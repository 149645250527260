import qs from 'qs'

const fields = [
]

export const queryEditionEntryThumbnails = (options: QueryManyOptions) => {
  const sortKey = strapiGetI18nSortKey(options?.locale, 'titles.i18n_string')

  const query = qs.stringify(
    {
      publicationState: options?.publicationState || 'live',
      sort: options?.sort || [`${sortKey}:desc`],
      pagination: {
        page: options?.page || 1,
        pageSize: options?.pageSize || 4,
      },
      fields,
      populate: {
        ...queryFragmentCollectionTypeDefault,
        cover_image: true,
        download: true
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `edition-entries?${query}`
}
